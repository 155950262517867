<template>
  <header-section/>

  <main>
    <!--    .container-->
    <div class="container mb-5 mt-5">
      <div class="mb-3 position-relative">
        <form @submit.prevent="search">
          <input v-model="searchInput" class="custom-input" placeholder="نماد مورد نظرت را جستجو کن..."/>
          <img  @click.prevent="search" src="@/assets/img/svg/search.svg" class="input-icon pointer-cursor" alt=""/>
        </form>
        <a v-if="isFilter() === true" @click.prevent="clearFilter" href="#"
           class="text-danger py-2 d-block text-start">
          <i class="fa fa-times align-middle"></i>
          پاکسازی تمام فیلتر ها
        </a>
      </div>
      <div class="custom-link-box ">
        <router-link :to="{ name: 'dictionary-show', params: { id: best.id } }" v-for="(best,index) in bestList" :key="index" href="#" class="custom-link2">
          {{best.title}}
        </router-link>
      </div>
    </div>
    <!--    /.container-->

    <div id="dictionary-list">
      <router-link v-for="(dictionary,index) in dictionaryList" :key="index"  :to="{ name: 'dictionary-show', params: { id: dictionary.id } }">
        <!--    .custom-box-->
        <div class="custom-box pointer-cursor mt-5 mb-5">
          <!--    .container-->
          <div class="container">
            <h2 class="title">{{dictionary.title}}</h2>
            <p class="text mb-5  overflow-nowrap-2">
              {{dictionary.description}}
            </p>
            <div class="custom-link-box">
              <a v-for="symbol in dictionary.symbol" :key="symbol.id" href="#" class="custom-link2">
                {{symbol.title}}
              </a>
            </div>

          </div>
          <!--    /.container-->
        </div>
        <!--    /.custom-box-->
      </router-link>

    </div>

      <div class="loading d-block text-center" v-if="loading === true">
        <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
      </div>

  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>

</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";


const searchInput = ref("");
const currentPage = ref(1);
const lastPage = ref(1);
const loading = ref(false);
const searchedText = ref("");
const dictionaryList = ref([]);
const bestList = ref([]);

getDictionaryList(1);
getDictionaryList(2);
function  search(){
  searchedText.value = searchInput.value;
  currentPage.value = 1;
  getDictionaryList(1,searchInput.value);
}
function isFilter() {
  return searchedText.value.toString().length > 0
}
function clearFilter() {
  searchInput.value = "";
  searchedText.value = "";
  getDictionaryList(1);
}
function  getDictionaryList(order_type,search = ""){
  loading.value = true;
  axios
      .post(helper.API_URL + '/api/v1/dictionary' , {
        'page' : currentPage.value,
        'search' : search,
        'order_type' : order_type,
      },{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          if(order_type == 1){
            lastPage.value = responseData.data.meta.last_page;
            if(currentPage.value == 1){
              dictionaryList.value =  responseData.data.dictionaryList;
            }
            else{
              let myData = responseData.data.dictionaryList;
              for(let i = 0;i< myData.length;i++){
                // //console.log(myData[i]);
                dictionaryList.value.push(myData[i])
              }
              // //console.log(  dictionaryList.value);
            }
          }else if(order_type == 2){
            bestList.value =  responseData.data.dictionaryList;
          }
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
          }
        }catch (e){
          //console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}

onMounted(function () {
  window.onscroll = () => {
    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    if (bottomOfWindow) {
      if(lastPage.value > currentPage.value){
        currentPage.value++;
        getDictionaryList(1,searchedText.value);
      }
    }
  };

})
function  back(){
  router.push('/');
}

</script>
<style scoped>

</style>