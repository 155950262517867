import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const helper = {
    'API_URL': 'https://api.app-hastikeshavarz.org',
    // 'API_URL': 'http://127.0.0.1:8000',
    'date': '',
    'getCurrentClient': function () {
        const data = localStorage.getItem("client")
        if (data != null) {
            var CryptoJS = require("crypto-js");
            var bytes = CryptoJS.AES.decrypt(localStorage.getItem("client"), 'WWQEDSZCdahjusgd7wqgdycasxss');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
            return null;
        }
    },
    'hasSubscription': function () {
        return helper.getCurrentClient().has_subscription == 1;
    },
    'getCurrentToken': function () {
        const data = localStorage.getItem("token")
        if (data != null) {
            var CryptoJS = require("crypto-js");
            var bytes = CryptoJS.AES.decrypt(data, 'WWQEDSZCdahjusgd7wqgdycasxss');
            return bytes.toString(CryptoJS.enc.Utf8);
        } else {
            return null;
        }

    },
    'getMode': function () {
        const data = localStorage.getItem("mode")
        if (data != null) {
            return data;
        } else {
            return "light";
        }
    },
    'setClient': function (value) {
        if (value == null) {
            localStorage.removeItem('client');
            return true;
        }
        var CryptoJS = require("crypto-js");
        const encryptData = CryptoJS.AES.encrypt(value, 'WWQEDSZCdahjusgd7wqgdycasxss').toString();
        localStorage.setItem('client', encryptData);
    },
    'setToken': function (value) {
        if (value == null) {
            localStorage.removeItem('token');
            return true;
        }
        var CryptoJS = require("crypto-js");
        const encryptData = CryptoJS.AES.encrypt(value, 'WWQEDSZCdahjusgd7wqgdycasxss').toString();
        localStorage.setItem('token', encryptData);
    },
    'setMode': function (value) {
        localStorage.setItem('mode', value);
    },
    refreshToken: async function () {
        const token = helper.getCurrentToken();
        if (token != null) {
            await axios
                .get(helper.API_URL + '/api/v1/client/me', {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
                .then(function (response) {
                    const responseData = response.data;
                    // //console.log(responseData);
                    if (!responseData.error) {
                        helper.date = responseData.data.date;
                        helper.setClient(JSON.stringify(responseData.data.client));
                    }
                })
                .catch(function (error) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "bottom",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    let errorMsg = "";
                    if (error.code == "ERR_NETWORK") {
                        errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
                    } else {
                        // const responseData = error.response.data;
                        // if (responseData.error) {
                        //     errorMsg = responseData.message;
                        // }
                        errorMsg = "خطا! ورود شما منقضی شده است لطفا دوباره وارد حساب کاربری خود شوید";
                        helper.setToken(null)
                        helper.setClient(null)
                        router.push({name: 'account'})
                    }
                    Toast.fire({
                        icon: "error",
                        title: errorMsg
                    });
                });
        }

    },
    routeMiddleware: function () {
        if (helper.getCurrentClient() == null) {
            router.push({'name': 'account'});
            return false
        }
        return true;
    },
    subscriptionMiddleware: function () {
        if (helper.getCurrentClient() == null) {
            router.push({'name': 'account'});
            return false
        } else if (!helper.hasSubscription()) {
            router.push({'name': 'home'});
            return false
        }
        return true;
    },
    numberFormat: function (n) {
        try {
            return n.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        } catch (error) {
            //console.log(error,n)
            return n;

        }
    },
    getDreamTimeText: function (n) {
        n = parseInt(n)
        switch (n) {
            case  1:
                return "شبانگاه"
            case 2:
                return "دمِ صبح"
            case 3:
                return "عصرگاه"
            default:
                return "نامشخص"
        }

    },
    isEmpty: function (s) {
        if (s.length == 0)
            return true;
        return false;
    },
    getRouteTitle: function (array, name) {
        return array.find(f => f.name == name).title;
    },
    getFelling: function (data,count) {
        let htmlData = "";
        // dataList.forEach(function (data) {
            switch (data) {
                case 1:
                    htmlData += '<div class="icon-1"></div> خوشحال'
                    break;
                case 2:
                    htmlData += '<div class="icon-2"></div> غمگین'
                    break;
                case 3:
                    htmlData += '<div class="icon-3"></div> خشمگین'
                    break;
                case 4:
                    htmlData += '<div class="icon-4"></div> ترسیده'
                    break;
                case 5:
                    htmlData += '<div class="icon-5"></div> مضطرب'
                    break;
                case 6:
                    htmlData += '<div class="icon-6"></div> خنثی'
                    break;
                case 7:
                    htmlData += '<div class="icon-7"></div> خجل'
                    break;
                case 8:
                    htmlData += '<div class="icon-8"></div> شرمگین'
                    break;
                case 9:
                    htmlData += '<div class="icon-9"></div> عصبی'
                    break;
                case 10:
                    htmlData += '<div class="icon-4"></div> متعجب'
                    break;
            }
            htmlData += "/"
        // })

        htmlData += "<span>" + count + " رؤیا </span>"
        return htmlData;
    },
    "validateEmail" : function (email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }
};