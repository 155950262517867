

<template>
 <header-section />
  <main>
    <!--    .custom-features-->
    <div v-show="page == 1" class="custom-features">
      <!--        .container-->
      <div class="container">
        <div class="row mt-5">
          <div class="col-6">
            <p class="features-title text-start">امکانات حساب کاربری</p>
          </div>
          <div class="col-3">
            <p class="features-title text-center">ویـــــژه</p>
          </div>
          <div class="col-3">
            <p class="features-title text-center">معمولی</p>
          </div>
        </div>
        <div class="line1"></div>
        <div class="row">
          <div class="col-6">
            <p class="text-features pt-2"> امکان ثبت نامحدود خواب و رؤیا</p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block pt-1 " alt="" />
            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto pt-1 " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2"> دسترسی نامحدود به لغتنامه تعبیر خواب ( با بیش از ۵۰ نماد کلیدی)</p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">دسترسی نامحدود به مفاهیم بنیادی روانشناسی تحلیلی</p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2"> امکان ضبط صدا برای هر رؤیا </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">  امکان افزودن عکس به هر رؤیا </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">   امکان دریافت فایل PDF از رؤیاها </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">  امکان دسته بندی رؤیاها بر اساس احساسات، کلمات کلیدی، تگ و... </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">   امکان جستجوی رؤیا بر اساس نمادهای کلیدی، تاریخ ثبت و... </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2"> امکان فعالسازی آلارم یادآودی </p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
          <div class="col-6">
            <p class="text-features pt-2">  امکان تغییر رنگ پس زمینه</p>
          </div>
          <div class="col-3">
            <div class="text-features">
              <img src="@/assets/img/svg/tik.svg" class="mx-auto d-block  " alt="" />

            </div>
          </div>
          <div class="col-3">
            <img src="@/assets/img/svg/close.svg" class="d-block mx-auto  " alt="" />
          </div>
        </div>
        <a href="#" @click.prevent="go(2)" class="btn-buy-subscription mt-5">خرید اشتراک</a>
        <div  class="custom-box  mt-5 mb-5 p-3">
          <div class="text-1" v-html="subscription_description"></div>
        </div>
      </div>

      <!--        /.container-->

    </div>
    <!--    /.custom-features-->

    <div v-show="page == 2">
      <!--    .container-->
      <div class="container">
        <div  class="custom-box pt-0 mt-5 mb-5">
          <div id="subscription-carousel" class="carousel slide mb-3" data-bs-interval="1000">
            <div class="carousel-indicators dir-ltr">
              <button v-for="(slide,index) in slides" :key="index" type="button" data-bs-target="#subscription-carousel" :data-bs-slide-to="index" :class="{active : index == 0}" :aria-current="index == 0 ? 'true':'false' "></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item" v-for="(slide,index) in slides" :key="index"  :class="{'active' : index == 0}">
                <div class="s1"></div>
                <div class="clearfix"></div>
                <h2 class="mt-1 title2">{{slide.title}}</h2>
                <p class="text2">{{slide.description}}</p>
                <div class="s2"></div>
                <div class="clearfix"></div>
                <p class="mb-3"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="subscription-abroad d-flex mb-0 " v-if="type == 1">
          <span class="icon-dollar"></span>

          <p class="text5">
            اگر کاربر خارج از ایران هستید با
            <a @click.prevent="type = 2;getSubscriptions()" href="#">
              پی پال
            </a>
            پرداخت کنید.
          </p>
        </div>


          <div class="subscription-list ">
            <div class="pointer-cursor" @click.prevent="payment(subscription.id)"  v-for="(subscription,index) in subscriptions" :key="index"  :class="{'subscription-box' : subscription.is_popular == 0,'subscription-vip' : subscription.is_popular == 1}">
              <div v-if="subscription.discount > 0" class="subscription-discount">
                %{{subscription.discount}}
                <br />
                رایگان
              </div>
              <h2>{{subscription.title}}</h2>
              <s v-if="subscription.discount > 0" class="discount">{{helper.numberFormat(subscription.price)}}
              <span v-if="type == 1"> تومان</span>
              <span v-if="type == 2"> یورو</span>
              </s>
              <p>
                {{helper.numberFormat(subscription['final_price'])}}
                <span v-if="type == 1"> تومان</span>
                <span v-if="type == 2"> یورو</span>
              </p>
            </div>
          </div>



      </div>
      <!--    /.container-->
    </div>



  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>


  <div class="modal fade" id="paymentModal" role="dialog" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            لینک پرداخت شما با موفقیت ایجاد شد
            <br />
            برای ورود به درگاه روی دکمه پرداخت کلیک کنید
          </p>
          <a @click="paymentModal.hide()"  :href="payment_url" target="_blank" class="btn-upgrade">پرداخت</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>


</template>

<script setup>
import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";
// import {Carousel, Modal} from "bootstrap";
import {Modal} from "bootstrap";


const page = ref("1");
const payment_url = ref("");
const slides = ref([]);
const subscription_description = ref("");
const type = ref(1);
const subscriptions = ref([]);
const loading = ref(false);
// let carousel = null;
let paymentModal = null;
getSlides();
getSubscriptions();

getSetting();
function  getSetting(){
  axios
      .get(helper.API_URL + '/api/v1/setting')
      .then(function (response) {
        const responseData = response.data;
        if (!responseData.error) {
          const setting = responseData.data;
            if(typeof setting['subscription_description'] !== undefined){
              subscription_description.value = setting['subscription_description'];
          }
        }
      })
      .catch(function (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        if(error.code == "ERR_NETWORK"){
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }else{
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}
function  getSlides(){
  axios
      .get(helper.API_URL + '/api/v1/slide/1' , { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        const responseData = response.data;
        if (!responseData.error) {
           slides.value = responseData.data;
        }
      })
      .catch(function (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        if(error.code == "ERR_NETWORK"){
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }else{
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}
function  getSubscriptions(){
  subscriptions.value = [];
  axios
      .get(helper.API_URL + '/api/v1/subscription/' + type.value , { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        const responseData = response.data;
        if (!responseData.error) {
          subscriptions.value = responseData.data;
        }
      })
      .catch(function (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        if(error.code == "ERR_NETWORK"){
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }else{
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}
function  go(p){
  page.value = p;
}
function   back(){
  if(page.value == 2){
    if(type.value == 2){
      type.value = 1;
      getSubscriptions();
    }else{
      page.value = 1;
    }
  }else{
    router.push('/')
    // router.go(-1);
  }
}


function  payment(id){
  if(loading.value == false){
    loading.value = true;
    axios
        .get(helper.API_URL + '/api/v1/payment/' + id, { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
        .then(function (response) {
          loading.value = false;

          const responseData = response.data;
          if (!responseData.error) {
            if(responseData.data.type == 1){
              payment_url.value = responseData.data.url;
              paymentModal.show();
            }else if(responseData.data.type == 2){
              helper.refreshToken().then(function () {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "bottom",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                  }
                });
                Toast.fire({
                  icon: "success",
                  title: responseData.message
                });
                router.push('/');
              });
            }
          }
        })
        .catch(function (error) {
          loading.value = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          let errorMsg = "";
          if(error.code == "ERR_NETWORK"){
            errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
          }else{
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
            }
          }
          Toast.fire({
            icon: "error",
            title: errorMsg
          });
        });
  }
}
onMounted(function () {
  paymentModal = new Modal(document.getElementById('paymentModal'));
  // carousel = new Carousel(document.getElementById('subscription-carousel'));
  // setInterval(function() {
  //     carousel.next();
  // }, 6000);
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState == "visible") {
      const oldSubscription = helper.getCurrentClient()['has_subscription'];
      helper.refreshToken().then(function () {
        if(oldSubscription == 0 && oldSubscription != helper.getCurrentClient()['has_subscription']){
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "success",
            title: "اشتراک با موفقیت خریداری شد"
          });
          router.push('/');
        }
      }
      );
    }
  });

})

</script>

<style scoped>

</style>