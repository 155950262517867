<template>
  <header-section/>
  <main>
    <!--    .container-->
    <div class="container">
      <div v-show="!loading">
        <div class="d-flex justify-content-between mt-5 align-items-center">
          <p class="text3">{{ dream.title }}</p>
          <p class="text4">
            {{ dream.date }} / {{ dream.time_type }}
          </p>
        </div>
        <div class="custom-border-bottom mb-3"></div>
        <p class="text5 pre-style ">
          {{ dream.description }}
        </p>
        <div class="custom-link-box mb-3">
          <router-link v-show="helper.hasSubscription()" :to="{ name: 'dictionary-show', params: { id: symbol.id } }" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
            {{ symbol.title }}
          </router-link>
          <a v-show="!helper.hasSubscription()"  data-bs-toggle="modal" data-bs-target="#vipModal" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
            {{ symbol.title }}
          </a>
        </div>
        <div class="mb-4">


          <div class="audio  mt-1" v-show="audio.length > 0">
            <audio  controls :src="audio">
            </audio>
          </div>



        </div>
        <img v-show="imageUrl.length > 0" :src="imageUrl" class="mb-5 w-50" alt="" />


        <div class=" mb-4" v-if="dream.past_event">
          <h2 class="title3">اتفاقات روز گذشته </h2>
          <div  class="events-text ">
            {{dream.past_event}}
          </div>
        </div>
      </div>


      <div class="loading d-block text-center mt-5" v-if="loading === true">
        <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
      </div>








    </div>
    <!--    /.container-->

  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>

  <div class="modal fade" id="vipModal" role="dialog" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            برای دسترسی به  لغتنامه, لطفاً حساب کاربریت رو ارتقاء بده!
          </p>
          <a @click.prevent="upgrade()" href="#" class="btn-upgrade">حسابم رو ارتقاء میدم</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">فعلاً نه</a>
        </div>
      </div>
    </div>
  </div>


</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import {useRoute} from "vue-router";
import router from "@/router";
import {Modal} from "bootstrap";


const loading = ref(false);
const dream= ref({});
const hasAudio = ref(false);
const imageUrl = ref("");
const audio = ref("");
const route = useRoute();
let vipModal = null;


getDream(route.params.id)


function  getDream(id){
  loading.value = true;
  axios
      .get(helper.API_URL + '/api/v1/dream/show/' + id ,{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          dream.value = responseData.data
          if(dream.value.files.find(f => f.file_type == '1')){
            imageUrl.value = dream.value.files.find(f => f.file_type == '1').file_url;
          }
          if(dream.value.files.find(f => f.file_type == '2')){
            // setAudio(dream.value.files.find(f => f.file_type == '2').file_url);
            hasAudio.value = true;
            audio.value = dream.value.files.find(f => f.file_type == '2').file_url;
            // setAudio("https://irsv.upmusics.com/AliBZ/Morteza%20Pashaei%20%7C%20Deleto%20Zdm%20(320).mp3");
          }
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }catch (e){
          //console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}

function  back(){
  console.log(router);
  router.go(-1);
}
function  upgrade(){
  vipModal.hide();
  router.push({name : 'subscription'});
}
onMounted(function () {
  vipModal = new Modal(document.getElementById('vipModal'));
});


</script>
<style scoped>

</style>