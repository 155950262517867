import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'


import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import 'bootstrap';

import './assets/css/fontiran.css'
import './assets/css/fonticon.min.css'
import './assets/css/style.css'
import './registerServiceWorker'


const app = createApp(App)
app.use(router)
app.mount('#app')
