<template>
  <router-view></router-view>
</template>

<script>

import {helper} from "@/helper";
export default {
  name: 'App',
  components: {
  },
  setup(){
    // if(update.updateExists){
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "bottom",
    //     showConfirmButton: false,
    //     timer: 600000,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.onmouseenter = Swal.stopTimer;
    //       toast.onmouseleave = Swal.resumeTimer;
    //     }
    //   });
    //   Toast.fire({
    //     icon: "info",
    //     title: "نسخه جدید یافت شد,در حال بروزرسانی..."
    //   });
    //   update.refreshApp();
    // }
    const mode = helper.getMode();
    if(mode == "dark"){
      document.body.classList.remove('light-mode')
      document.body.classList.add('dark-mode')
      helper.setMode('dark')
    }else{
      document.body.classList.remove('dark-mode')
      document.body.classList.add('light-mode')
      helper.setMode('light')
    }

  },
  beforeMount(){
    helper.refreshToken();
    // function getLocalStream() {
    //   navigator.mediaDevices
    //       .getUserMedia({ video: false, audio: true })
    //       .then((stream) => {
    //         window.localStream = stream;
    //         window.localAudio.srcObject = stream;
    //         window.localAudio.autoplay = true;
    //       })
    //       .catch((err) => {
    //         console.error(`you got an error: ${err}`);
    //       });
    // }
    //
    // getLocalStream();
  }
}


</script>

<style>

</style>
