

<template>
  <header-section />
  <main>
    <!--    .home-slider-->
    <div class="home-slider">
        <div id="home-carousel" class="carousel slide pb-4" data-bs-interval="1000">
          <div class="carousel-indicators dir-ltr">
            <button v-for="(slide,index) in slides" :key="index" type="button" data-bs-target="#home-carousel" :data-bs-slide-to="index" :class="{active : index == 0}" :aria-current="index == 0 ? 'true':'false' "></button>
          </div>
          <div class="carousel-inner">
            <div class="container">
              <div class="carousel-item" v-for="(slide,index) in slides" :key="index"  :class="{'active' : index == 0}">
                <h2 class="t1">{{slide.title}}</h2>
                <h3 class="t2">{{slide.subtitle}}</h3>
                <p class="t3">{{slide.description}}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
    <!--    /.home-slider-->



    <!--    .container-->
    <div class="container">
      <!--        .home-box-->
      <div @click="goToPage(1)" class="home-box mt-5 pointer-cursor">
        <div class="d-flex justify-content-right align-items-center">
          <img src="@/assets/img/bg1.png" class="ms-2 home-box-img" alt=""/>
          <div class="d-flex ms-3">
            <img src="@/assets/img/svg/notebook.svg" class="align-middle pe-2" alt="" />
            <p>
              دفترچه یادداشت رؤیا
            </p>
          </div>
        </div>

      </div>
      <!--        /.home-box-->
      <!--        .home-box-->
      <div @click="goToPage(2)"  class="home-box pointer-cursor d-none">
        <div class="d-flex justify-content-right align-items-center">
          <img src="@/assets/img/bg2.png" class="ms-2 home-box-img" alt=""/>
          <div class="d-flex ms-3">
            <img src="@/assets/img/svg/book.svg" class="align-middle pe-2" alt="" />
            <p>
              لغتنامه نمادشناسی
            </p>
          </div>

        </div>

      </div>
      <!--        /.home-box-->
      <!--        .home-box-->
      <div @click="goToPage(3)"  class="home-box pointer-cursor">
        <div class="d-flex justify-content-right align-items-center">
          <img src="@/assets/img/bg3.png" class="ms-2 home-box-img" alt=""/>
          <div class="d-flex ms-3">
            <img src="@/assets/img/svg/psychology.svg" class="align-middle pe-2" alt="" />
            <p>
              مفاهیم بنیادی
              <br/>
              روانشناسی تحلیلی
            </p>
          </div>
        </div>

      </div>
      <!--        /.home-box-->

    </div>
    <!--    /.container-->
    <div >
      <div class="home-logo"></div>
    </div>


  </main>
  <div class="modal fade" id="vipModal" role="dialog" >
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            برای دسترسی به مفاهیم بنیادی روانشناسی تحلیلی لطفاً حساب کاربریت رو ارتقاء بده!
          </p>
          <a @click.prevent="upgrade()" href="#" class="btn-upgrade">حسابم رو ارتقاء میدم</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">فعلاً نه</a>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="loginModal" role="dialog" >
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            سلام!
            <br />
            برای استفاده از این بخش وارد حساب کاربری خود شوید
          </p>
          <a @click.prevent="goToAccount()"  href="#" class="btn-upgrade">ورود به حساب کاربری</a>
          <a @click.prevent="goToAccount()"  href="#" class="btn-cancellation">ساخت حساب کاربری</a>
        </div>
      </div>

    </div>
  </div>

</template>


<script setup>
    import HeaderSection from "@/components/HeaderSection.vue";
    import router from "@/router";
    import {helper} from "@/helper";
    import {onMounted, ref} from "vue";
    // import {Carousel, Modal} from "bootstrap";
    import {Modal} from "bootstrap";
    import axios from "axios";
    import Swal from "sweetalert2";


    let vipModal = null;
    let loginModal = null;
    // let carousel = null;
    const slides = ref([]);

    getSlides();


    function  goToPage(type){
      if(type == 1){
        if(helper.getCurrentClient() == null){
          loginModal.show();
        }else{
          router.push({name : 'dream-index'})
        }
      }else if(type == 2){
        if(helper.getCurrentClient() == null){
          loginModal.show();
        }
        else if(!helper.hasSubscription()){
          vipModal.show();
        }else{
          router.push({name : 'dictionary-index'})
        }
      }else if(type == 3){
        if(helper.getCurrentClient() == null){
          loginModal.show();
        }else if(!helper.hasSubscription()){
          vipModal.show();
        }else {
          router.push({name: 'concept-index'})
        }
      }
      // if(type == 2)
      // {
      // }
    }
    function  getSlides(){
      axios
          .get(helper.API_URL + '/api/v1/slide/2' , { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
          .then(function (response) {
            const responseData = response.data;
            if (!responseData.error) {
              slides.value = responseData.data;
            }
          })
          .catch(function (error) {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            let errorMsg = "";
            if(error.code == "ERR_NETWORK"){
              errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
            }else{
              const responseData = error.response.data;
              if (responseData.error) {
                errorMsg = responseData.message;
              }
            }
            Toast.fire({
              icon: "error",
              title: errorMsg
            });
          });
    }

    function  upgrade(){
      vipModal.hide();
      router.push({name : 'subscription'});
    }
    function  goToAccount(){
      loginModal.hide();
      router.push({name : 'account'});
    }

    onMounted(function () {
      vipModal = new Modal(document.getElementById('vipModal'));
      loginModal = new Modal(document.getElementById('loginModal'));
      // self.addEventListener("visibilitychange", function () {
      //   console.log("Visibility changed");
      //   if (document.visibilityState === "visible") {
      //     console.log("APP resumed");
      //     window.location.reload();
      //   }
      // });

      // carousel = new Carousel(document.getElementById('home-carousel'));
      // setInterval(function() {
      //   carousel.next();
      // }, 6000);

    })

</script>

<style scoped>

</style>