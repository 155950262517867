<template>
  <header-section/>
  <main>
    <!--    .container-->
    <div class="container">
      <div v-if="step == 1">
        <div class="dream-step-content">
          <div class="d-flex justify-content-between align-items-center mt-5">
          <div class="d-flex">
            <a @click.prevent="showDataPicker = true" href="#" class="d-flex align-items-center custom-link7 border-0">
              <span class="icon-date2"></span>
              <span v-if="date.length > 0">
           {{ date }}
          </span>
              <span v-else>
            تاریخ
          </span>
            </a>
            <a href="#" class="d-flex align-items-center custom-link7 border-0 ms-2" @click="openTimeModal">
              <span class="icon-time"></span>
              <span v-if="time > 0">
           {{ helper.getDreamTimeText(time) }}
          </span>
              <span v-else>
            زمان
          </span>
            </a>
          </div>

            <a href="#" class="d-flex align-items-center custom-link7 border-0 ms-2" @click.prevent="selectAudio">
              <span class="icon-audio"></span>
            </a>

          </div>
          <div class="line1 mt-2 mb-4"></div>
          <input v-model="title" type="text" class="custom-input2" placeholder="موضوع رؤیا (مثال: افتادن دندان، فرار...)">
          <textarea :style="{ height: textareaHeight }"  @input="handleInput"  v-model="description" placeholder="جزئیات رؤیا...&#10;
 به منظور تشخیص و نمایش معنی نمادهای خوابت، لطف کن کلمات را کامل بنویس.&#10;
 مثال: خانه به جای خونه، حیوان به جای حیوون و..."
                    class="custom-text-area"></textarea>
          <input  v-model="tag" type="text" class="custom-input2 d-none" placeholder="#تگ های شخصی را وارد کنید(حداکثر ۵)">

          <div class="audio  mt-2" v-show="audio.length > 0">
            <audio  controls :src="audio">
            </audio>
            <div>
              <a data-bs-toggle="modal" data-bs-target="#deleteAudioModal" href="#" class="btn btn-danger btn-sm mb-3">
                <i class="fa fa-trash"></i>
                حذف صدا
              </a>
            </div>
          </div>
          <img :src="picture" class="w-50 d-block" alt=""/>
          <a v-if="picture.length > 0" data-bs-toggle="modal" data-bs-target="#deletePictureModal" href="#"
             class="btn btn-danger btn-sm my-2">
            <i class="fa fa-trash"></i>
            حذف تصویر
          </a>
        </div>
         <a @click.prevent="nextStep" href="#" class="btn-continue">
          ادامه
        </a>
      </div>
      <div v-if="step == 2">
        <div class="dream-step-content2">
          <h2 class="title5 mt-5">احساسی که در حین خواب دیدن داشتید...</h2>
          <div class="custom-link-box mb-3 ">
            <a :class="{'active':checkItemCustomSelect(1,1)}" @click.prevent="customSelect(1,1,$event)" href="" class=" custom-link3">
              <div class="icon-1"></div>
              خوشحال
            </a>
            <a :class="{'active':checkItemCustomSelect(1,2)}" @click.prevent="customSelect(1,2,$event)" href="" class=" custom-link3">
              <div class="icon-2"></div>
              غمگین
            </a>
            <a :class="{'active':checkItemCustomSelect(1,3)}" @click.prevent="customSelect(1,3,$event)" href="" class=" custom-link3">
              <div class="icon-3"></div>
              خشمگین
            </a>
            <a :class="{'active':checkItemCustomSelect(1,7)}" @click.prevent="customSelect(1,7,$event)" href="" class=" custom-link3">
              <div class="icon-7"></div>
              خجل
            </a>
            <a :class="{'active':checkItemCustomSelect(1,4)}" @click.prevent="customSelect(1,4,$event)" href="" class=" custom-link3">
              <div class="icon-4"></div>
              ترسیده
            </a>
            <a :class="{'active':checkItemCustomSelect(1,5)}" @click.prevent="customSelect(1,5,$event)" href="" class=" custom-link3">
              <div class="icon-5"></div>
              مضطرب
            </a>
            <a :class="{'active':checkItemCustomSelect(1,6)}" @click.prevent="customSelect(1,6,$event)" href="" class=" custom-link3">
              <div class="icon-6"></div>
              خنثی
            </a>
            <a :class="{'active':checkItemCustomSelect(1,8)}" @click.prevent="customSelect(1,8,$event)" href="" class=" custom-link3">
              <div class="icon-8"></div>
              شرمگین
            </a>
            <a :class="{'active':checkItemCustomSelect(1,9)}" @click.prevent="customSelect(1,9,$event)" href="" class=" custom-link3">
              <div class="icon-9"></div>
              عصبی
            </a>
            <a :class="{'active':checkItemCustomSelect(1,10)}" @click.prevent="customSelect(1,10,$event)" href="" class=" custom-link3">
              <div class="icon-4"></div>
              متعجب
            </a>
          </div>
          <div class="line1 mb-3"></div>
          <h2 class="title3">
            دسته بندی رؤیا
            <span>(اختیاری)</span>
          </h2>
          <div class="custom-link-box mb-3">
            <a @click.prevent="customSelect(2,1,$event)" href="#" class="custom-link4">
              رؤیای تکراری
            </a>
            <a @click.prevent="customSelect(2,2,$event)" href="#" class="custom-link4 ">
              رؤیای شفاف
            </a>
            <a @click.prevent="customSelect(2,3,$event)" href="#" class="custom-link4 ">
              کابوس
            </a>
          </div>
          <div class="line1 mb-3"></div>
          <div class=" mb-5">
            <h2 class="title3">
              اتفاقات روز گذشته
              <span>(اختیاری)</span>
            </h2>
            <div class="events-text ">
              <textarea :style="{ height: textareaHeight2 }"  @input="handleInput2"  v-model="past_event" placeholder="۱-مثال: به دلیل فلان اتفاق استرس داشتم یا ...&#10;
 2-&#10;
 3-&#10;
 4-"
                        class="custom-text-area"></textarea>

            </div>
          </div>
        </div>
        <div class="text-rules">
          ثبت رؤیا به منزله موافقت با
          <router-link target="_blank"  :to="{name : 'custom-page',params:{type : 'privacy'}}"> قوانین انتشار رؤیا</router-link>
          می باشد.
        </div>
        <a href="#" @click.prevent="nextStep" class="btn-submit">
          <div
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"></div>
          ثبت رؤیا
        </a>
      </div>


    </div>
    <!--    /.container-->

  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back" href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <a href="#">
      </a>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>




  <input type="hidden" id="hidden_date"/>
  <date-picker :max="helper.date" custom-input="#hidden_date" class="custom-datapicker" color="#698E83"
               @close="showDataPicker=false"
               :show="showDataPicker" format="jYYYY/jMM/jDD" v-model="date"></date-picker>

  <input accept=".png,.jpg,.jpeg" class="d-none" id="input_picture" type="file" @change="onFileChange(1,$event)"/>


  <div class="modal fade bottom-sheet" id="timeModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <a @click.prevent="selectTime(1)" href="#" class="d-flex mb-2">
            <div class="custom-active" :class="{'active':time == 1}">
              <div class="icon-time1"></div>
            </div>
            <p class="text-time">شبانگاه</p>
          </a>

          <a @click.prevent="selectTime(2)" href="#" class="d-flex mb-2">
            <div class="custom-active" :class="{'active':time == 2}">
              <div class="icon-time2"></div>
            </div>
            <p class="text-time">دمِ صبح</p>
          </a>
          <a @click.prevent="selectTime(3)" href="#" class="d-flex mb-2">
            <div class="custom-active" :class="{'active':time == 3}">
              <div class="icon-time3"></div>
            </div>
            <p class="text-time">عصرگاه</p>
          </a>
        </div>
      </div>

    </div>
  </div>

  <div class="modal fade" id="vipModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            برای دسترسی به این بخش
            حساب کاربری خود را ارتقاء دهید.
          </p>
          <a @click.prevent="upgrade()" href="#" class="btn-upgrade">ارتقاء حساب</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="deletePictureModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">آیا تمایل دارید عکس را از یادداشت حذف کنید؟</p>
          <a @click="deletePicture" href="#" class="btn-upgrade">بله</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="deleteAudioModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4"> دوست داری صداتو از این رؤیا حذف کنی؟</p>
          <a @click="deleteAudio" href="#" class="btn-upgrade">بله</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">نه، پشیمون شدم</a>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade bottom-sheet" id="recordAudioModal" role="dialog">
    <div class="modal-dialog">


      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-center">
            ضبط صدا
          </p>

          <RecordsAudio v-show="!savedRecord" :saveRecord="saveRecord" :iconsRecord="iconsRecord"
                        @listenEventsRecord="listenEventsRecord" @listenEventsPlayer="listenEventsPlayer">
            <!-- control de grabador -->
            <template #btnCancelRecord="{ControllerRecord}">
              <button v-show="isRecording" class="btn btn-sm btn-transparent" @click="ControllerRecord.cancelRecord">
                <!--                <IconClose/>-->
                <img alt="" src="@/assets/img/audio-delete.png"/>
              </button>
            </template>
            <template #btnRecord="{ControllerRecord}">
              <button class="btn btn-transparent" @click="ControllerRecord.playAudio">
                <img v-if="!ControllerRecord.record" alt="" src="@/assets/img/audio-record.png"/>
                <img v-else-if="!ControllerRecord.pause" alt="" src="@/assets/img/audio-stop.png"/>
                <img v-else alt="" src="@/assets/img/audio-play.png"/>

                <!--                <IconMic v-if="!ControllerRecord.record"/>-->
                <!--                <IconPause color="#ffffff" v-else-if="!ControllerRecord.pause" />-->
                <!--                <IconPlay color="#ffffff" v-else/>-->
              </button>
            </template>
            <template #btnStopRecord="{ControllerRecord}">
              <button v-show="isRecording" class="btn btn-sm btn-transparent" @click="ControllerRecord.stopRecord">
                <img alt="" src="@/assets/img/audio-check.png"/>
                <!--                <IconStop/>-->
              </button>
            </template>
            <!-- control de player -->
            <template #btnPlayerCancel="{ControllerPlayer}">
              <button id="btnCancelRecord" class="btn btn-sm btn-transparent" @click="ControllerPlayer.cancelPlay">
                <img alt="" src="@/assets/img/audio-delete.png"/>
                <!--                <IconClose/>-->
              </button>
            </template>
            <template #btnPlayer="{ControllerPlayer}">
              <button class="btn btn-transparent" @click="ControllerPlayer.playRecord">
                <img v-if="ControllerPlayer.play" alt="" src="@/assets/img/audio-stop.png"/>
                <img v-else-if="ControllerPlayer.pause" alt="" src="@/assets/img/audio-play.png"/>
                <img v-else alt="" src="@/assets/img/audio-play.png"/>

                <!--                <IconPause color="#ffffff" v-if="ControllerPlayer.play"/>-->
                <!--                <IconPlay color="#ffffff" v-else-if="ControllerPlayer.pause" />-->
                <!--                <IconPlay color="#ffffff" v-else/>-->
              </button>
            </template>
            <template #btnPlayerStop="{ControllerPlayer}">
              <button class="btn btn-sm btn-transparent" @click="ControllerPlayer.stopPlay">
                <img alt="" src="@/assets/img/audio-check.png"/>
                <!--                <IconStop/>-->
              </button>
            </template>
          </RecordsAudio>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import router from "@/router";
import {onMounted, ref} from "vue";
import DatePicker from 'vue3-persian-datetime-picker'
import {Modal} from "bootstrap";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import axios from "axios";
import {RecordsAudio} from "vue-record-audio";


const date = ref("");
if (!helper.isEmpty(helper.date)) {
  date.value = helper.date;
}
const time = ref("1");
const title = ref("");
const description = ref("");
const tag = ref("");
const picture = ref("");
const audio = ref("");
const audio_file = ref("");
const past_event = ref("");
const showDataPicker = ref(false);
const step = ref(1);
const felling_type_id = ref([]);
const category_type_id = ref([]);
const loading = ref(false);
const hasDeletePicture = ref(false);
const hasDeleteAudio = ref(false);
const isRecording = ref(false);
const savedRecord = ref(false);

let deletePictureModal = null;
let deleteAudioModal = null;
let recordAudioModal = null;
let voiceData = ref(null);

let timeModal = null;
let vipModal = null;


const textareaHeight = ref('150px');
const textareaHeight2 = ref('180px');

const saveRecord = (data) => {
  voiceData.value = data;
  savedRecord.value = true;
  setTimeout(
      function () {
        document.getElementById('btnCancelRecord').click();
        savedRecord.value = false;
      }, 500);
  recordAudioModal.hide();
  audio.value = data.base64;
  fetch(data.base64)
      .then(res => res.blob())
      .then(blob => {
        audio_file.value = new File([blob], "test.webm",{ type: "audio/mpeg" });
        console.log('seted',audio_file.value);
      })
  console.log('save_record', data.base64)
}


const listenEventsRecord = (data) => {
  isRecording.value = data.record;
  console.log("listen record", data)
}
const listenEventsPlayer = (data) => {
  console.log("listen player", data)
}

function back() {
  if (step.value == 2) {
    step.value = 1;
  } else {
    router.go(-1);
  }
}

function openTimeModal() {
  timeModal.show();
}

function selectTime(type) {
  time.value = type;
  timeModal.hide();
}

function  selectAudio(){
  if(!helper.hasSubscription()){
    vipModal.show();
  }
  else{
    recordAudioModal.show();
  }
}
// function selectPicture() {
//   if (!helper.hasSubscription()) {
//     vipModal.show();
//   } else {
//     let picture = document.getElementById("input_picture");
//     picture.click();
//   }
// }

function upgrade() {
  vipModal.hide();
  router.push({name: 'subscription'});
}


function  customSelect(type,value,event){
  if(type ==  1){
    if(felling_type_id.value.indexOf(value) === -1){
      if(felling_type_id.value.length <= 1){
        event.target.classList.add('active');
        felling_type_id.value.push(value)
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "warning",
          title: "فقط امکان انتخاب ۲ مورد وجود دارد!"
        });
      }

    }else{
      event.target.classList.remove('active');
      felling_type_id.value.splice(felling_type_id.value.indexOf(value), 1);
    }

  } else {
    if (category_type_id.value.indexOf(value) === -1) {
      event.target.classList.add('active');
      category_type_id.value.push(value)
    } else {
      event.target.classList.remove('active');
      category_type_id.value.splice(category_type_id.value.indexOf(value), 1);
    }
  }
}

function onFileChange(type, e) {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length)
    return;
  var reader = new FileReader();
  reader.onload = (e) => {
    if (type == 1) {
      picture.value = e.target.result;
    }
  };
  reader.readAsDataURL(files[0]);


}

function nextStep() {
  if (loading.value) {
    return false;
  }
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  if (step.value == 1) {
    if (helper.isEmpty(date.value)) {
      Toast.fire({
        icon: "warning",
        title: "لطفا تاریخ را مشخص کنید"
      });
      return false;
    }
    if (helper.isEmpty(time.value)) {
      Toast.fire({
        icon: "warning",
        title: "لطفا زمان را مشخص کنید"
      });
      return false;
    }
    if (helper.isEmpty(title.value)) {
      Toast.fire({
        icon: "warning",
        title: "لطفا موضوع رؤیا را تکمیل کن"
      });
      return false;
    }
    if (helper.isEmpty(description.value) && helper.isEmpty(audio.value)) {
      Toast.fire({
        icon: "warning",
        title: "لطفا جزئیات رؤیا رو تکمیل کن"
      });
      return false;
    }
    // if(helper.isEmpty(tag.value)){
    //   Toast.fire({
    //     icon: "warning",
    //     title: "لطفا تگ ها را تکمیل کنید"
    //   });
    //   return false;
    // }
    step.value = 2;
  } else if (step.value == 2) {
    if (helper.isEmpty(felling_type_id)) {
      Toast.fire({
        icon: "warning",
        title: "لطفا احساسات خود را مشخص کنید"
      });
      return false;
    }
    // if(helper.isEmpty(category_type_id)){
    //   Toast.fire({
    //     icon: "warning",
    //     title: "لطفا دسته بندی  را مشخص کنید"
    //   });
    //   return false;
    // }

    loading.value = true;

    let postData = {
      'date': date.value,
      'time_type': time.value,
      'title': title.value,
      'description': description.value,
      'category_type_id': category_type_id.value.toString(),
      'felling_type_id': felling_type_id.value.toString(),
      'tag': tag.value,
      'past_event': past_event.value,
    }
    if (!helper.isEmpty(audio.value)) {

      postData.audio = audio_file.value;
      // postData.audio = dataURLtoFile(audio.value)
      // console.log(postData.audio);
      // postData.audio = document.getElementById('input_audio').files[0];
    }
    if (!helper.isEmpty(picture.value)) {
      postData.picture = document.getElementById('input_picture').files[0]
    }


    axios
        .post(helper.API_URL + '/api/v1/dream/create', postData, {
          headers: {
            "Authorization": `Bearer ${helper.getCurrentToken()}`,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          loading.value = false;
          const responseData = response.data;
          if (!responseData.error) {
            Toast.fire({
              icon: "success",
              title: responseData.message
            });
            router.push({'name': 'dream-index'});
          }
        })
        .catch(function (error) {
          loading.value = false;
          let errorMsg = "";
          try {
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
            }
          } catch (e) {
            errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
          }
          Toast.fire({
            icon: "error",
            title: errorMsg
          });
        });


  }
}




  // var arr = dataurl.split(','),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[arr.length - 1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  // while(n--){
  //   u8arr[n] = bstr.charCodeAt(n);
  // }
  // return new File([u8arr], filename, {type:mime});


function deletePicture() {
  deletePictureModal.hide();
  hasDeletePicture.value = true;
  picture.value = "";
  document.getElementById('input_picture').value = null;
}

function deleteAudio() {
  deleteAudioModal.hide();
  hasDeleteAudio.value = true;
  audio.value = "";
}
function  checkItemCustomSelect(type,value){
  if(type == 1){
    if(felling_type_id.value.indexOf(value) === -1) {
      return false;
    }else{
      return  true;
    }
  }else{
    if(category_type_id.value.indexOf(value) === -1) {
      return false;
    }else{
      return  true;
    }
  }
}

const handleInput = (event) => {
  description.value = event.target.value;
  adjustHeight(event.target);
};
const handleInput2 = (event) => {
  past_event.value = event.target.value;
  adjustHeight(event.target);
};

const adjustHeight = (el) => {
  // el.style.height = 'auto'; // Reset height to auto to calculate the new height
  // Use timeout to allow the DOM to update before calculating scrollHeight
  textareaHeight.value = el.scrollHeight + 'px'; // Set the height based on the scrollHeight
};



onMounted(function () {
  timeModal = new Modal(document.getElementById('timeModal'));
  vipModal = new Modal(document.getElementById('vipModal'));
  deleteAudioModal = new Modal(document.getElementById('deleteAudioModal'));
  deletePictureModal = new Modal(document.getElementById('deletePictureModal'));
  recordAudioModal = new Modal(document.getElementById('recordAudioModal'));
})
//@import url(/node_modules/vue-record-audio/dist/style.css);

</script>


<style>

.spectrogram > div {
  display: none !important;
}

.container-canvas {
  display: none;
}

.upload-button {
  display: none;
}

.container-record {
  display: flex;
  flex-direction: column;
  text-align: center;
  direction: ltr;
}

.view-record {
  order: 1;
}

.buttons-record {
  order: 2;
}

.buttons-record img {
  width: 48px;
  height: 48px;
}

button, button:focus, button:focus-visible, button:active {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: unset !important;
}
</style>