

<template>
  <header-section />
  <main>
    <div class="custom-box = mt-5">
      <!--    .container-->
      <div class="container">
        <h2 class="title">{{title}}</h2>
        <p class="text" v-html="description"></p>

      </div>
      <!--    /.container-->
    </div>

  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>
</template>
<script setup>
import {onUpdated, ref} from "vue";
    import HeaderSection from "@/components/HeaderSection.vue";
    import {useRoute} from "vue-router";
    import {helper} from "@/helper";
    import axios from "axios";
    import Swal from "sweetalert2";
import router from "@/router";



    const title = ref("");
    const description = ref("");
    const route = useRoute();
    const type = ref(route.params.type);

    getSetting();
    function  getSetting(){
      axios
          .get(helper.API_URL + '/api/v1/setting' )
          .then(function (response) {
            const responseData = response.data;
            if (!responseData.error) {
              const setting = responseData.data;
              if(type.value == "privacy"){
                title.value = "قوانین و مقرّرات";
                if(typeof setting['privacy_description'] !== undefined){
                  description.value = setting['privacy_description'];
                }
              }else if(type.value == "faq"){
                title.value = "سوالات متداول";
                if(typeof setting['faq_description'] !== undefined){
                  description.value = setting['faq_description'];
                }
              }else{
                //not found
              }
            }
          })
          .catch(function (error) {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              }
            });
            let errorMsg = "";
            if(error.code == "ERR_NETWORK"){
              errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
            }else{
              const responseData = error.response.data;
              if (responseData.error) {
                errorMsg = responseData.message;
              }
            }
            Toast.fire({
              icon: "error",
              title: errorMsg
            });
          });
    }


onUpdated(function () {
  type.value = route.params.type
  getSetting()
})
function  back() {
  router.go(-1);
}
</script>

<style scoped>

</style>