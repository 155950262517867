<template>
  <header v-if="user != null" class="">
    <nav class="navbar">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-start menu-box"  data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" id="offcanvasNavbar"
             aria-labelledby="offcanvasNavbarLabel" >

          <div class="close-box">
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

          </div>
          <!--                    <div class="offcanvas-header">-->
          <!--                    </div>-->
          <div class="offcanvas-body">
            <div class="logo-navbar">
              <img :src="user['avatar']" class="mx-auto wh-73 rounded-circle d-block mb-2" alt=""/>
              <p class="mb-1">
                {{ user['name'] }}
                جان سلام!</p>
              <p class="font-weight-400 font-size-12" v-if="user['has_subscription'] == 1">
                باقیمانده اشتراک:
                  {{user['subscription_expire2']}}
              </p>
              <p class="font-weight-400" v-else>
                بدون اشتراک
              </p>
            </div>

            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item">
                <router-link :to="{name :  'edit-profile'}" class="nav-link active nav-text" aria-current="page" href="#">
                  <img src="@/assets/img/svg/account_circle.svg" class="align-middle" alt=""/>
                  حساب کاربری
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{name :  'subscription'}" class="nav-link active nav-text" aria-current="page" href="#">
                  <img src="@/assets/img/svg/upgrade.svg" class="align-middle" alt=""/>
                  ارتقاء حساب
                </router-link>
              </li>
              <li class="nav-item">
                <div class="d-flex justify-content-between">
                  <a     @click.prevent="backup();" class="nav-link active nav-text" aria-current="page" href="#">
                    <img src="@/assets/img/svg/picture_as_pdf.svg" class="align-middle" alt=""/>
                    دریافت بک آپ
                  </a>
                  <a href="#">
                    <img v-if="user['has_subscription'] == 0" src="@/assets/img/svg/lock.svg" class="align-middle" alt=""/>
                  </a>
                </div>
              </li>
              <li  @click="openCustomModal(2)"  class="nav-item">
                <div class="d-flex justify-content-between">
                  <a class="nav-link active nav-text" aria-current="page" href="#">
                    <img src="@/assets/img/svg/colorize.svg" class="align-middle" alt=""/>
                    رنگ پس زمینه
                  </a>
                  <div class="form-check form-switch">
                    <input :disabled="user['has_subscription'] == 0" @change.prevent="changeMode($event)" v-model="inputChangeMode" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                  </div>
                  <a  v-if="user['has_subscription'] == 0" href="#">
                    <img src="@/assets/img/svg/lock.svg" class="align-middle" alt=""/>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <a data-bs-dismiss="offcanvas" aria-label="Close"  @click="goToPage('faq')" class="nav-link active nav-text" aria-current="page" href="#">
                  <img src="@/assets/img/svg/faq.svg" class="align-middle" alt=""/>

                  سوالات متداول
                </a>
              </li>
              <li class="nav-item">
                <a data-bs-dismiss="offcanvas" aria-label="Close" @click="goToPage('privacy')"  class="nav-link active nav-text" aria-current="page" href="#">
                  <img src="@/assets/img/svg/policy.svg" class="align-middle" alt=""/>
                  قوانین و مقرّرات
                </a>
              </li>

              <li class="nav-item">
                <div class="d-flex justify-content-between">
                  <a @click="openCustomModal(3)"   :target="user['has_subscription'] == 0 ? '_self':'blank'"   class="nav-link active nav-text" aria-current="page"  :href="user['has_subscription'] == 1 ? 'https://t.me/office_hastike' : '#'">
                    <img src="@/assets/img/svg/report_problem.svg" class="align-middle" alt=""/>
                    گزارش مشکلات فنی
                  </a>
                  <a href="#">
                    <img v-if="user['has_subscription'] == 0" src="@/assets/img/svg/lock.svg" class="align-middle" alt=""/>
                  </a>
                </div>
              </li>
              <li class="nav-item">
                <a  data-bs-toggle="modal" data-bs-target="#logoutModal" class="nav-link active nav-text text-red"  href="#">
                  <img src="@/assets/img/svg/logout.svg" class="align-middle" alt=""/>
                  خروج از حساب کاربری
                </a>
              </li>

            </ul>
            <div class="footer-menu">
              <p>طراح رابط و تجربه کاربری: هستی کشاورز</p>
              <p class="mb-0">تمامی حقوق برای هستی کشاورز محفوظ است.</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>


  <div class="modal fade" id="logoutModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">دوست داری از حساب کاربریت خارج شی؟</p>
          <a @click="logout" href="#" class="btn-upgrade">بله</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close"> نه پشیمون شدم</a>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="customModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">{{modalText}}</p>
          <a @click="upgrade" href="#" class="btn-upgrade">{{modalConfirm}}</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">{{modalCancel}}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="backupModal" role="dialog" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            لینک بک اپ شما با موفقیت ایجاد شد
            <br />
            برای مشاهده و دانلود روی دکمه زیر کلیک کنید
          </p>
          <a @click="backupModal.hide()"  :href="backup_url" target="_blank" class="btn-upgrade">مشاهده و دانلود</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>

</template>

<script setup>
import {helper} from "@/helper";
import {onMounted, ref} from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import {Modal} from "bootstrap";

let logoutModal = null;
const user = ref(helper.getCurrentClient());
const inputChangeMode = ref(false);
const backup_url = ref("");
const loading = ref(false);
const  currentMode = helper.getMode();
if(currentMode == "dark"){
  inputChangeMode.value = true;
}

const modalText = ref("");
const modalConfirm = ref("");
const modalCancel = ref("");
let customModal = null;
let backupModal = null;


function  changeMode(){
  if(inputChangeMode.value){
    document.body.classList.remove('light-mode')
    document.body.classList.add('dark-mode')
    helper.setMode('dark')
  }else{
    document.body.classList.remove('dark-mode')
    document.body.classList.add('light-mode')
    helper.setMode('light')
  }
}

function  logout(){
  logoutModal.hide();
  axios
      .get(helper.API_URL + '/api/v1/client/logout', { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function () {
        helper.setClient(null)
        helper.setToken(null)
        router.push({'name' : 'account'});
      })
      .catch(function () {
        // helper.setClient(null)
        // helper.setToken(null)
        // router.push({'name' : 'account'});
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "error",
          title: "خطا ! لطفا دوباره تلاش کنید"
        });
      });

}

function  backup(){
  if(user.value['has_subscription'] == 1 && loading.value == false){
    axios
        .get(helper.API_URL + '/api/v1/dream/export', { headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
        .then(function (response) {
          const responseData = response.data;
          if (!responseData.error) {
            backup_url.value =responseData.data.url;
            backupModal.show();
            // window.open(responseData.data.url, '_blank');
            // console.log(responseData.data.url);
          }

        })
        .catch(function () {
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          Toast.fire({
            icon: "error",
            title: "خطا ! لطفا دوباره تلاش کنید"
          });
        });
  }else{
    openCustomModal(1);
  }
}


function goToPage(type){
  router.push({name : 'custom-page',params:{type : type}})
}

onMounted(function () {
  logoutModal = new Modal(document.getElementById('logoutModal'));
  customModal = new Modal(document.getElementById('customModal'));
  backupModal = new Modal(document.getElementById('backupModal'));

})

function  openCustomModal(type){
  if(user.value['has_subscription'] == 0){
    modalConfirm.value = 'حسابم رو ارتقاء میدم';
    modalCancel.value = 'فعلاً نه';
    if(type == 1){
      modalText.value = "برای دریافت فایل PDF لطفاً حساب کاربریت رو ارتقاء بده!";
    }else if(type == 2){
      modalText.value = "برای تغییر تم رنگی لطفاً حساب کاربریت رو ارتقاء بده!";
    }else if(type == 3){
      modalText.value = "برای گزارش فنی لطفاً حساب کاربریت رو ارتقاء بده!";
    }
    customModal.show();
  }

}
function  upgrade(){
  customModal.hide();
  router.push({name : 'subscription'});
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
