import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "@/pages/HomePage.vue";
import AccountPage from "@/pages/AccountPage.vue";
import {helper} from "@/helper";
import CustomPage from "@/pages/CustomPage.vue";
import SubscriptionPage from "@/pages/SubscriptionPage.vue";
import DictionaryIndexPage from "@/pages/dictionary/IndexPage.vue";
import DictionaryShowPage from "@/pages/dictionary/ShowPage.vue";
import ConceptIndexPage from "@/pages/concept/IndexPage.vue";
import ConceptShowPage from "@/pages/concept/ShowPage.vue";
import DreamIndexPage from "@/pages/dream/IndexPage.vue";
import DreamShowPage from "@/pages/dream/ShowPage.vue";
import DreamAddPage from "@/pages/dream/AddPage.vue";
import DreamEditPage from "@/pages/dream/EditPage.vue";
import DreamSymbolPage from "@/pages/dream/SymbolPage.vue";
import DreamIndexPage2 from "@/pages/dream/IndexPage2.vue";
import EditProfilePage from "@/pages/EditProfilePage.vue";


const routes = [
    { path: '/', name: 'home', component: HomePage ,beforeEnter: () => {
           return true;
    },title:'پلتفرم خواب و رؤیا'},
    { path: '/dictionary', name: 'dictionary-index', component: DictionaryIndexPage ,beforeEnter: () => {
            return helper.subscriptionMiddleware();
        },title: ' لغتنامه نمادشناسی'},
    { path: '/dictionary/:id', name: 'dictionary-show', component: DictionaryShowPage ,beforeEnter: () => {
            return helper.subscriptionMiddleware();
        },title: 'مشاهده لغتنامه'},
    { path: '/concept', name: 'concept-index', component: ConceptIndexPage ,beforeEnter: () => {
            return helper.subscriptionMiddleware();
        },title: 'مفاهیم بنیادی روانشناسی تحلیلی'},
    { path: '/concept/:id', name: 'concept-show', component: ConceptShowPage ,beforeEnter: () => {
            return helper.subscriptionMiddleware();
        },title: 'مشاهده مفاهیم'},
    { path: '/dream', name: 'dream-index', component: DreamIndexPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: ' دفترچه یادداشت رؤیا'},
    { path: '/dream/:id', name: 'dream-show', component: DreamShowPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'مشاهده یادداشت'},
    { path: '/dream/add', name: 'dream-add', component: DreamAddPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'یادداشت جدید'},
    { path: '/dream/symbol', name: 'symbol', component: DreamSymbolPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'نماد های من'},
    { path: '/dream/symbol/:id', name: 'dream-list-with-symbol', component: DreamIndexPage2 ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'لیست رؤیاها'},
    { path: '/dream/edit/:id', name: 'dream-edit', component: DreamEditPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'ویرایش یادداشت'},

    { path: '/subscription', name: 'subscription', component: SubscriptionPage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'خرید اشتراک'},
    { path: '/page/:type', name: 'custom-page', component: CustomPage ,beforeEnter: () => {
            return helper.routeMiddleware();
     },title: ''},
    { path: '/edit-profile', name: 'edit-profile', component: EditProfilePage ,beforeEnter: () => {
            return helper.routeMiddleware();
        },title: 'ویرایش پروفایل'},
    { path: '/account', name: 'account', component: AccountPage,  beforeEnter: () => {
            if(helper.getCurrentClient() != null){
                router.push({'name' : 'home'});
                return false;
            }
            return  true;
        },title: 'ورود یا ثبت نام'}
];


const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    let title = helper.getRouteTitle(routes,to.name);
    if(to.name == "home" || to.name == "custom-page"){
        document.title = title;
    }else{
        document.title = title + " - پلتفرم خواب و رؤیا";
    }
    next();
});
export default router;