<template>
  <header-section/>
  <main>
    <!--    .container-->
    <div class="container">
      <div class="mt-5 mb-4">
        <a class="w-100 custom-dropdown" data-bs-toggle="collapse" href="#collapseExample" role="button"
           aria-expanded="false" aria-controls="collapseExample">
          <!--        .home-box-->
          <div class="home-box">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-right align-items-center">
                <img src="@/assets/img/img-1.png" class="ms-2 home-box-img" alt=""/>
                <div class="d-flex ms-3">
                  <img src="@/assets/img/svg/notebook.svg" class="align-middle pe-2" alt=""/>
                  <p>
                    مفاهیم
                  </p>
                </div>
              </div>
              <i class="fa fa-angle-down angle"></i>
            </div>

          </div>
          <!--        /.home-box-->
        </a>
        <div class="collapse" id="collapseExample">
          <div>
            <div class="row">
              <div class="col-4 mb-3" v-for="(concept,index) in conceptList1" :key="index">
                <router-link :to="{ name: 'concept-show', params: { id: concept.id } }">
                  <div class="concept-box" :style="{ 'background-image': 'url('+ concept.picture +')' }">
                    <div class="custom-text">
                      {{ concept.title }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <a class="w-100 custom-dropdown" data-bs-toggle="collapse" href="#collapseExample2" role="button"
           aria-expanded="false" aria-controls="collapseExample">
          <!--        .home-box-->
          <div class="home-box">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-right align-items-center">
                <img src="@/assets/img/img-2.png" class="ms-2 home-box-img" alt=""/>
                <div class="d-flex ms-3">
                  <img src="@/assets/img/svg/book.svg" class="align-middle pe-2" alt=""/>
                  <p>
                    کهن الگوها
                  </p>
                </div>
              </div>
              <i class="fa fa-angle-down angle"></i>
            </div>

          </div>
          <!--        /.home-box-->
        </a>
        <div class="collapse" id="collapseExample2">
          <div>
            <div class="row">
              <div class="col-4 mb-3" v-for="(concept,index) in conceptList2" :key="index">
                <router-link :to="{ name: 'concept-show', params: { id: concept.id } }">
                  <div class="concept-box" :style="{background:'url(\'' + concept.picture+ '\')'}">
                    <div class="custom-text">
                      {{ concept.title }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   / .container-->
  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>




</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import {ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";


const conceptList1 = ref([]);
const conceptList2 = ref([]);
getConceptList(1);
getConceptList(2);

function getConceptList(concept_type) {
  axios
      .get(helper.API_URL + '/api/v1/concept/' + concept_type , {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
      .then(function (response) {
        const responseData = response.data;
        if (!responseData.error) {
         if(concept_type == 1)
           conceptList1.value = responseData.data;
         else
           conceptList2.value = responseData.data;
        }
      })
      .catch(function (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        } catch (e) {
          // //console.log(e, error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}


function back() {
  router.push('/');
  // router.go(-1);
}

</script>
<style scoped>

</style>