<template>
  <main>
    <!--    .account-container-->
    <div class="account-container">
      <!--    .account-box-->
      <div class="account-box shadow-lg w-90">
        <div v-if="page == 1">
          <form>
            <h2 class="account-title">ورود / ثبت نام</h2>
            <input v-model="userInfo" type="text" class="account-input" placeholder="ایمیل"/>
            <small class="form-text text-danger">{{ errorText }}</small>
            <button :disabled="loading" @click.prevent="checkAccount" class="account-btn">
              <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"></div>
              مرحله بعد
            </button>

          </form>
        </div>
        <div v-else-if="page == 2">
          <form>
            <h2 class="account-title">ساخت حساب کاربری</h2>
            <p class="account-timer">{{timerText}}</p>
            <input v-model="fullName" type="text" class="account-input" placeholder="نام"/>
            <input v-model="birthYear" type="number" class="account-input" placeholder="سال تولد"/>
            <select v-model="gender" class="account-select" >
              <option value="">لطفا جنسیت خود را مشخص کنید</option>
              <option value="1">مرد</option>
              <option value="2">زن</option>
              <option value="3">دیگر</option>
            </select>

            <input v-model="code" type="number" class="account-input mb-0 " placeholder="کد تاییدیه ارسالی"/>
            <div class="d-flex justify-content-end">
              <a  v-if="timerSecond == 0" @click.prevent="sendCode(userInfo)" class="text-secondary mb-1"   href="#">
                ارسال مجدد کد
              </a>
            </div>
            <small class="form-text text-danger mt-2">{{ errorText }}</small>
            <button :disabled="loading" @click.prevent="register" class="account-btn">
              <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"></div>
              ثبت نام
            </button>

          </form>
        </div>
        <div v-else-if="page == 3">
          <form>
            <p class="account-timer">{{timerText}}</p>
            <h2 class="account-title">
              کد ارسال شده به  ایمیل خود را وارد کنید
            </h2>
            <input v-model="loginCode" type="number" class="account-input mb-2" placeholder="کد تاییدیه ارسالی" />
           <div class="d-flex justify-content-end">
             <a  v-if="timerSecond == 0" @click.prevent="sendCode(userInfo)" class="text-secondary mb-1"   href="#">
               ارسال مجدد کد
             </a>
           </div>

            <small class="form-text text-danger">{{ errorText }}</small>
            <button :disabled="loading" @click.prevent="login" class="account-btn">
              <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"></div>
              ورود
            </button>
          </form>
        </div>

      </div>
      <!--   / .account-box-->
    </div>
    <!--    /.account-container-->

  </main>
  <footer>
    <a @click.prevent="back" href="#" class="footer-icon">
      <i class="fa fa-arrow-right align-middle"></i>
    </a>
  </footer>
</template>


<script setup>


import {ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import router from "@/router";


const userInfo = ref("");
const fullName = ref("");
const birthYear = ref("");
const gender = ref("");
const code = ref("");
const loginCode = ref("");
const errorText = ref("");
const loading = ref(false);
const timerSecond = ref(180);
const timerText = ref("3:00");
const page = ref("1");
let intervalId = null


function checkAccount() {
  if (userInfo.value.length > 0) {
    loading.value = true;
    axios
        .post(helper.API_URL + '/api/v1/client/check', {
          user_info: userInfo.value,
        })
        .then(function (response) {
          errorText.value = "";
          loading.value = false;
          const responseData = response.data;
          if (!responseData.error) {
            sendCode(userInfo.value);
            if (responseData.data.type == 1) {
              // //console.log("login");
              page.value =  3;
            } else {
              // //console.log("register");
              page.value =  2;
            }
          }
        })
        .catch(function (error) {
          loading.value = false;
          if(error.code == "ERR_NETWORK"){
            errorText.value = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
          }else{
              const responseData = error.response.data;
              if (responseData.error) {
                errorText.value = responseData.message;
              }
          }
        });
  } else {
    errorText.value = "خطا ! لطفا اطلاعات مورد نیاز را به صورت صحیح وارد کنید";
  }
}

function register() {
  if (fullName.value.toString().length == 0 || birthYear.value.toString().length == 0 || gender.value.toString().length == 0 || code.value.toString().length == 0) {
    errorText.value = "لطفا اطلاعات درخواستی را تکمیل کنید"
    return false;
  }
  if (birthYear.value <= 1300 || birthYear.value >= 1400) {
    errorText.value = "لطفا تاریخ تولد خود را صحیح وارد کنید"
    return false;
  }
  if (code.value.toString().length != 4) {
    errorText.value = "لطفا کد تایید را صحیح وارد کنید"
    return false;
  }
  errorText.value = "";
  //send request to server


  axios
      .post(helper.API_URL + '/api/v1/client/register', {
        user_info: userInfo.value,
        name: fullName.value,
        birth_year: birthYear.value,
        gender: gender.value,
        code: code.value,
      })
      .then(function (response) {
        errorText.value = "";
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          helper.setClient(JSON.stringify(responseData.data.client));
          helper.setToken(responseData.data.token);
          helper.refreshToken()
          router.push('/');
        }
        })
      .catch(function (error) {
        loading.value = false;
        if(error.code == "ERR_NETWORK"){
          errorText.value = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }else{
          const responseData = error.response.data;
          if (responseData.error) {
            errorText.value = responseData.message;
          }
        }
      });



}
function login() {
  if (loginCode.value.toString().length != 4) {
    errorText.value = "لطفا کد تایید را صحیح وارد کنید"
    return false;
  }
  errorText.value = "";
  //send request to server


  axios.post(helper.API_URL + '/api/v1/client/login', {
        user_info: userInfo.value,
        code: loginCode.value,
      })
      .then(function (response) {
        errorText.value = "";
        loading.value = false;

        const responseData = response.data;
        if (!responseData.error) {
          helper.setClient(JSON.stringify(responseData.data.client));
          helper.setToken(responseData.data.token);
          helper.refreshToken()
          router.push('/');
        }
      })
      .catch(function (error) {
        loading.value = false;
        if(error.code == "ERR_NETWORK"){
          errorText.value = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }else{
          const responseData = error.response.data;
          if (responseData.error) {
            errorText.value = responseData.message;
          }
        }
      });



}

function  sendCode(target_user_info){
  startTimer()

  axios
      .post(helper.API_URL + '/api/v1/client/sendCode', {
        user_info: target_user_info,
      })
      .then(function () {
        errorText.value = "";
        loading.value = false;
        // const responseData = response.data;
      })
      .catch(function () {
        loading.value = false;
        // const responseData = error.response.data;
      });
}
function  back() {
    if(page.value == 2 || page.value == 3){
      page.value = 1;
      fullName.value = "";
      birthYear.value = "";
      gender.value = "";
      code.value = "";
      loginCode.value = "";
      errorText.value = "";
    }else{
      router.push('/');
    }
}

function  startTimer() {
  if (intervalId) {
    clearInterval(intervalId);
    timerSecond.value = 180;
  }
  intervalId = setInterval(() => {
    if (timerSecond.value === 0) {
      clearInterval(intervalId);
    } else {
      timerSecond.value--;
    }
    timerText.value = formattedTime();
  }, 1000);
}
function  formattedTime() {
  const minutes = Math.floor((timerSecond.value % 3600) / 60);
  const seconds = timerSecond.value % 60;

  return `${padZero(minutes)}:${padZero(seconds)}`;
}
function  padZero(value) {
  return value.toString().padStart(2, '0');
}


</script>

<style scoped>

</style>