<template>
  <header-section/>
  <main>
    <div class="loading d-block text-center mt-5" v-if="loading === true">
      <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
    </div>
    <div v-if="loading === false" class="custom-box  mt-5">
      <!--    .container-->
      <div class="container">
        <h2 class="title-1">{{concept.title}}</h2>
        <h2 class="title">{{concept.subtitle}}</h2>
        <p class="text mb-5" v-html="concept.description"></p>
        <div class="custom-link-box">
          <router-link :to="{ name: 'dictionary-show', params: { id: symbol.id } }" v-for="symbol in concept.symbol" :key="symbol.id" class="custom-link">
            {{ symbol.title }}
          </router-link>
        </div>
      </div>
      <!--    /.container-->
    </div>

  </main>

  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>




</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import {useRoute} from "vue-router";
import router from "@/router";


const loading = ref(false);
const concept= ref({});
const route = useRoute();

getConcept(route.params.id)

function  getConcept(id){
  loading.value = true;
  axios
      .get(helper.API_URL + '/api/v1/concept/show/' + id ,{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          concept.value = responseData.data
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }catch (e){
          //console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });


}
onMounted(function () {
  getConcept(route.params.id)
})
function  back(){
  router.go(-1);
}


</script>
<style scoped>

</style>