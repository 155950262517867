<template>
  <header-section/>

  <main>

    <div class="loading d-block text-center mt-5" v-if="loading === true">
      <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
    </div>
      <!--    .custom-box-->
      <div v-if="loading === false"  class="custom-box  mt-5 mb-5">
        <!--    .container-->
        <div class="container">
          <div  class="symbol-info">
            <div class="bg1"  :style="{ 'background-image': 'url('+ dictionary.picture +')' }" ></div>
            <div class="bg2"></div>
            <div class="symbol-info-text p-4">
              <h3>{{dictionary.title}}</h3>
            </div>
          </div>
<!--          <h2 class="title">{{dictionary.title}}</h2>-->
          <p class="text mb-5" v-html="dictionary.description">
          </p>
          <div class="custom-link-box">
             <router-link :to="{ name: 'dictionary-show', params: { id: symbol.id } }" @click="getDictionary(symbol.id)" href="#" v-for="symbol in dictionary.symbol" :key="symbol.id" class="custom-link2">
              {{symbol.title}}
            </router-link>
          </div>

        </div>
        <!--    /.container-->
      </div>
      <!--    /.custom-box-->



  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>

</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import {useRoute} from "vue-router";
import router from "@/router";


const loading = ref(false);
const dictionary= ref({});
const route = useRoute();
const id = ref(route.params.id)



onMounted(function () {
  getDictionary(id.value)
})

function  getDictionary(id){
  loading.value = true;
  axios
      .get(helper.API_URL + '/api/v1/dictionary/show/' + id ,{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          dictionary.value = responseData.data
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
          }
        }catch (e){
          //console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });


}
function  back(){
  router.go(-1);
}



</script>
<style scoped>

</style>