<template>
  <header-section/>
  <main>
    <div v-show="!loading" class="custom-box symbol-box  rounded-0  mt-4">
      <!--    .container-->
      <div class="container">

        <div v-if="!helper.isEmpty(symbol)" class="symbol-info">
          <div class="bg1"  :style="{ 'background-image': 'url('+ symbol.dictionary.picture +')' }" ></div>
          <div class="bg2"></div>
          <div class="symbol-info-text p-4">
            <h3>{{symbol.dictionary.title}} /</h3>
            <span>{{symbol.count}} رؤیا</span>
          </div>
        </div>

        <div class="dream-list">
          <div  v-for="(dream) in dreamList" :key="dream.id">
            <router-link :to="{ name: 'dream-show', params: { id: dream.id } }">
              <div  class="custom-dream pointer-cursor">
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <h2 class="tilte-2">{{ dream.title }}</h2>
                  <p class="text-1">
                    {{ dream.date }} / {{ dream.time_type }}
                  </p>
                </div>
                <p class="text-2 overflow-nowrap-2">
                  {{ dream.description }}
                </p>
                <div class="d-flex justify-content-between">
                  <div class="custom-link-box">
                    <div class="custom-link-box">
                      <router-link v-show="helper.hasSubscription()" :to="{ name: 'dictionary-show', params: { id: symbol.id } }" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
                        {{ symbol.title }}
                      </router-link>
                      <a v-show="!helper.hasSubscription()"  data-bs-toggle="modal" data-bs-target="#vipModal" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
                        {{ symbol.title }}
                      </a>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <img v-if="dream.files.find(f => f.file_type == '1')" src="@/assets/img/svg/image.svg" class="mx-1"
                         alt=""/>
                    <img v-if="dream.files.find(f => f.file_type == '2')" src="@/assets/img/svg/keyboard_voice.svg" alt="" class="mx-1"/>
                    <router-link :to="{name : 'dream-edit',params: { id: dream.id } }" href="#" class="mx-1 mode_edit">
                    </router-link>
                    <a @click.prevent="openDeleteModal(dream.id)" class="delete mx-1" href="#" ></a>
                  </div>
                </div>

              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!--    /.container-->


        <div class="loading d-block text-center" v-if="loading === true">
          <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
        </div>
      </div>
  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>
  <!-- Modal -->

  <div class="modal fade" id="deleteModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">رؤیای مورد نظر حذف گردد؟</p>
          <a @click="deleteDream" href="#" class="btn-upgrade">بله</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>

  <div class="modal fade" id="vipModal" role="dialog" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            برای دسترسی به  لغتنامه, لطفاً حساب کاربریت رو ارتقاء بده!
          </p>
          <a @click.prevent="upgrade()" href="#" class="btn-upgrade">حسابم رو ارتقاء میدم</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">فعلاً نه</a>
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>
import { Modal } from 'bootstrap';
import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";
import {useRoute} from "vue-router";


const currentPage = ref(1);
const lastPage = ref(1);
const loading = ref(false);
const dreamList = ref([]);
const symbol = ref([]);
const deletedId = ref(null);
let deleteModal = null;
const route = useRoute();
let vipModal = null;

getSymbol(route.params.id);
getDreamList(route.params.id);

function  openDeleteModal(value){
  deletedId.value = value;
  deleteModal.show();
}
function  deleteDream(){
  deleteModal.hide();
  if(deletedId.value > 0){
    axios
        .delete(helper.API_URL + '/api/v1/dream/' + deletedId.value, {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
        .then(function (response) {
          loading.value = false;
          const responseData = response.data;
          if (!responseData.error) {
            currentPage.value = 1;
            getDreamList(route.params.id);
          }
        })
        .catch(function (error) {
          loading.value = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          let errorMsg = "";
          try {
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
            }
          } catch (e) {
            //console.log(e, error);
            errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
          }
          Toast.fire({
            icon: "error",
            title: errorMsg
          });
        });
  }

}
function getDreamList(id) {
  loading.value = true;
  axios
      .post(helper.API_URL + '/api/v1/dream/symbol/dream/' + id, {
        'page': currentPage.value,
      }, {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          lastPage.value = responseData.data.meta.last_page;
          if (currentPage.value == 1) {
            dreamList.value = responseData.data.dreamList;
          } else {
            let myData = responseData.data.dreamList;
            for (let i = 0; i < myData.length; i++) {
              dreamList.value.push(myData[i])
            }
          }
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        } catch (e) {
          //console.log(e, error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}
function  getSymbol(id){
  loading.value = true;
  axios
      .get(helper.API_URL + '/api/v1/dream/symbol/show/' + id ,{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          symbol.value = responseData.data
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }catch (e){
          //console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}


onMounted(function () {

  deleteModal = new Modal(document.getElementById('deleteModal'));
  vipModal = new Modal(document.getElementById('vipModal'));

  window.onscroll = () => {
    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    if (bottomOfWindow) {
      if (lastPage.value > currentPage.value) {
        currentPage.value++;
        getDreamList(route.params.id);
      }
    }
  };

})

function  upgrade(){
  vipModal.hide();
  router.push({name : 'subscription'});
}
function back() {
  router.go(-1);
}


</script>
<style scoped>

</style>