<template>
  <header-section/>
  <main>
    <div class="mt-5">
      <!--    .container-->
      <div class="container">
        <div class="mb-3 d-flex "  v-if="(!helper.isEmpty(dreamList) || isFilter() === true) && loading === false">
          <a @click.prevent="showDataPicker = true" href="#" class="icon-date me-4"></a>
          <router-link :to="{name : 'symbol'}" href="#" class="icon-symbol w-28 h-28 me-4"></router-link>
          <a href="#" class="icon-filter me-4" @click.prevent="orderTypeModal.show()"></a>
          <a href="#"  class="icon-search me-4" @click.prevent="showSearch = !showSearch"></a>
        </div>
        <div class="mb-3 position-relative">
          <Transition>
          <form @submit.prevent="search" v-show="showSearch && !loading">
            <input v-model="searchInput" class="custom-input" placeholder="نماد کلیدی مورد نظرت را جستجو کن..."/>
            <img @click.prevent="search" src="@/assets/img/svg/search.svg" class="input-icon" alt=""/>
          </form>
          </Transition>
          <a v-if="isFilter() === true && !loading" @click.prevent="clearFilter" href="#"
             class="text-danger py-2 d-block text-start">
            <i class="fa fa-times align-middle"></i>
            پاکسازی تمام فیلتر ها
          </a>
        </div>

        <div class="dream-list">
          <div  v-for="(dream, index) in dreamList" :key="dream.id">
            <div class="felling-type-text d-flex" v-if="orderType == 3 && (dreamList[index-1] == null || dream.felling_type_id != dreamList[index-1].felling_type_id)" v-html="helper.getFelling(dream.felling_type_id,dream.felling_count)">
            </div>
            <router-link :to="{ name: 'dream-show', params: { id: dream.id } }">
            <div  class="custom-dream pointer-cursor">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <h2 class="tilte-2">{{ dream.title }}</h2>
                <p class="text-1">
                  {{ dream.date }} / {{ dream.time_type }}
                </p>
              </div>
              <p class="text-2 overflow-nowrap-2">
                {{ dream.description }}
              </p>
              <div class="d-flex justify-content-between">
                <div class="custom-link-box">
                  <router-link v-show="helper.hasSubscription()" :to="{ name: 'dictionary-show', params: { id: symbol.id } }" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
                    {{ symbol.title }}
                  </router-link>
                  <a v-show="!helper.hasSubscription()"  data-bs-toggle="modal" data-bs-target="#vipModal" v-for="symbol in dream.symbol" :key="symbol.id" href="#" class="custom-link">
                    {{ symbol.title }}
                  </a>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <img v-if="dream.files.find(f => f.file_type == '1')" src="@/assets/img/svg/image.svg" class="mx-1"
                       alt=""/>
                  <img v-if="dream.files.find(f => f.file_type == '2')" src="@/assets/img/svg/keyboard_voice.svg" alt="" class="mx-1"/>
                  <router-link :to="{name : 'dream-edit',params: { id: dream.id } }" href="#" class="mx-1 mode_edit">
                  </router-link>
                  <a @click.prevent="openDeleteModal(dream.id)" class="delete mx-1" href="#" ></a>
                </div>
              </div>

            </div>
          </router-link>
          </div>
        </div>


        <div class="loading d-block text-center" v-if="loading === true">
          <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
        </div>

        <div v-if="helper.isEmpty(dreamList) && loading === false && !showSearch && !isFilter()" style="text-align: center;vertical-align: center;position: absolute;top:50%;right:50px;left:50px;font-size:14px;font-weight:300">
          {{helper.getCurrentClient()['name']}}
           جان هنوز هیچ رؤیایی در دفترچه رؤیات ثبت نشده، برای شروع ثبت رؤیا، لطفا آیکون بعلاوه را لمس کن.
        </div>


      </div>
      <!--   / .container-->

    </div>
  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>
  <!-- Modal -->

  <div class="modal fade" id="deleteModal" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">رؤیای مورد نظر حذف گردد؟</p>
          <a @click="deleteDream" href="#" class="btn-upgrade">بله</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">انصراف</a>
        </div>
      </div>

    </div>
  </div>

  <div class="modal fade bottom-sheet" id="orderTypeModal" role="dialog">
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <a @click="changeOrderType(1)" href="#" class="d-flex mb-2">
            <div  class="custom-active" :class="{'active':orderType == 1}">
              <div class="icon-filter1"></div>
            </div>
            <p class="text-filter">نمایش به ترتیب از رؤیاهای جدید</p>
          </a>
          <a @click="changeOrderType(2)" href="#" class="d-flex mb-2">
            <div  class="custom-active" :class="{'active':orderType == 2}">
              <div class="icon-filter2"></div>
            </div>
            <p class="text-filter">نمایش به ترتیب از رؤیاهای قدیم</p>
          </a>
          <a @click="changeOrderType(3)" href="#" class="d-flex mb-2">
            <div  class="custom-active" :class="{'active':orderType == 3}">
              <div class="icon-filter3"></div>
            </div>
            <p class="text-filter">نمایش بر اساس دسته بندی احساسات</p>
          </a>
        </div>
      </div>

    </div>
  </div>
  <div class="modal fade" id="vipModal" role="dialog" >
    <div class="modal-dialog">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-body">
          <p class="text-delete mt-4">
            برای دسترسی به  لغتنامه, لطفاً حساب کاربریت رو ارتقاء بده!
          </p>
          <a @click.prevent="upgrade()" href="#" class="btn-upgrade">حسابم رو ارتقاء میدم</a>
          <a href="#" class="btn-cancellation" data-bs-dismiss="modal" aria-label="Close">فعلاً نه</a>
        </div>
      </div>
    </div>
  </div>


  <input type="hidden" id="hidden_date"/>
  <date-picker custom-input="#hidden_date" class="custom-datapicker" color="#698E83" @close="showDataPicker=false"
               :show="showDataPicker" format="jYYYY/jMM/jDD" range v-model="date"></date-picker>


</template>
<script setup>
import { Modal } from 'bootstrap';
import DatePicker from 'vue3-persian-datetime-picker'
import HeaderSection from "@/components/HeaderSection.vue";
import {onMounted, ref, watch} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";


const searchInput = ref("");
const currentPage = ref(1);
const lastPage = ref(1);
const loading = ref(false);
const searchedText = ref("");
const dreamList = ref([]);
const date = ref("");
const showDataPicker = ref(false);
const orderType = ref(1);
const deletedId = ref(null);
const showSearch = ref(false);
let orderTypeModal = null;
let deleteModal = null;
let vipModal = null;


getDreamList();

function search() {
  searchedText.value = searchInput.value;
  currentPage.value = 1;
  getDreamList(searchInput.value);
}
function  changeOrderType(type){
  orderType.value = type;
  orderTypeModal.hide()
}
function  openDeleteModal(value){
  deletedId.value = value;
  deleteModal.show();
}
function  deleteDream(){
  deleteModal.hide();
  if(deletedId.value > 0){
    axios
        .delete(helper.API_URL + '/api/v1/dream/' + deletedId.value, {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
        .then(function (response) {
          loading.value = false;
          const responseData = response.data;
          if (!responseData.error) {
            currentPage.value = 1;
            getDreamList(searchedText.value);
          }
        })
        .catch(function (error) {
          loading.value = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            }
          });
          let errorMsg = "";
          try {
            const responseData = error.response.data;
            if (responseData.error) {
              errorMsg = responseData.message;
            }
          } catch (e) {
            //console.log(e, error);
            errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
          }
          Toast.fire({
            icon: "error",
            title: errorMsg
          });
        });
  }

}
function getDreamList(search = "") {
  if (currentPage.value == 1) {
    dreamList.value = [];
  }
    let start_time = "";
  let end_time = "";
  if (date.value.length == 1) {
    start_time = date.value[0]
    end_time = date.value[0]
  } else if (date.value.length == 2) {
    start_time = date.value[0]
    end_time = date.value[1]
  }
  //console.log({
  //   'page': currentPage.value,
  //   'search': search,
  //   'order_type': orderType.value,
  //   'start_time': start_time,
  //   'end_time': end_time,
  // });
  loading.value = true;
  axios
      .post(helper.API_URL + '/api/v1/dream', {
        'page': currentPage.value,
        'search': search,
        'order_type': orderType.value,
        'start_time': start_time,
        'end_time': end_time,
      }, {headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`}})
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          lastPage.value = responseData.data.meta.last_page;
          if (currentPage.value == 1) {
            dreamList.value = responseData.data.dreamList;
          } else {
            let myData = responseData.data.dreamList;
            for (let i = 0; i < myData.length; i++) {
              dreamList.value.push(myData[i])
            }
          }
        }
      })
      .catch(function (error) {
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        } catch (e) {
          //console.log(e, error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}
watch(
    date,
    async () => {
      currentPage.value = 1;
      getDreamList(searchedText.value);
    },
    { immediate: true }
)
watch(
    orderType,
    async () => {
      currentPage.value = 1;
      getDreamList(searchedText.value);
    },
    { immediate: true }
)

function clearFilter() {
  searchInput.value = "";
  searchedText.value = "";
  date.value = "";
  getDreamList("");
}
function isFilter() {
  return searchedText.value.toString().length > 0 || date.value.toString().length > 0
}

onMounted(function () {

  orderTypeModal = new Modal(document.getElementById('orderTypeModal'));
  deleteModal = new Modal(document.getElementById('deleteModal'));
  vipModal = new Modal(document.getElementById('vipModal'));


  window.onscroll = () => {
    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    if (bottomOfWindow) {
      if (lastPage.value > currentPage.value) {
        currentPage.value++;
        getDreamList(searchedText.value);
      }
    }
  };

})
function  upgrade(){
  vipModal.hide();
  router.push({name : 'subscription'});
}
function back() {
  router.push('/');
}


</script>
<style scoped>

</style>