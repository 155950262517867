<template>
  <header-section/>
  <main>

      <div v-show="!loading" class="custom-box symbol-box  rounded-0  mt-4">
        <!--    .container-->
        <div class="container">
          <div class="d-flex align-items-center ">
            <span class="icon-symbol"></span>
            <span class="symbol-text1">
            نمادهای من
            </span>
          </div>

          <div class="row mt-3">
            <div @click.prevent="openDreamList(symbol.id)" class="col-6 col-sm-4 col-lg-3 pointer-cursor"  v-for="symbol in symbolList" :key="symbol.id">
              <div class="symbol-item">
                <div class="bg1"  :style="{ 'background-image': 'url('+ symbol.dictionary.picture +')' }" ></div>
                <div class="bg2"></div>
                <div class="symbol-item-text">
                  <h3>{{symbol.dictionary.title}}</h3>
                  <span>{{symbol.count}} رؤیا</span>
                </div>
              </div>
            </div>


          </div>

        </div>
        <!--    /.container-->
      </div>


    <!--    .container-->
    <div class="container">
      <div class="loading d-block text-center mt-5" v-if="loading === true">
        <span class="fa fa-spinner fa-spin"></span> لطفا منتظر باشید
      </div>

    </div>
    <!--    /.container-->








  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>





</template>
<script setup>

import HeaderSection from "@/components/HeaderSection.vue";
import { ref} from "vue";
import axios from "axios";
import {helper} from "@/helper";
import Swal from "sweetalert2";
import router from "@/router";


const loading = ref(false);
const symbolList = ref([]);

getSymbolList()


function  getSymbolList(){
  loading.value = true;
  axios
      .get(helper.API_URL + '/api/v1/dream/symbol' ,{ headers: {"Authorization" : `Bearer ${helper.getCurrentToken()}`} })
      .then(function (response) {
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          symbolList.value = responseData.data
        }
      })
      .catch(function (error) {
        console.log("sina error",error);
        loading.value = false;
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        }catch (e){
          console.log(e,error);
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });
}

function  back(){
  router.go(-1);
}

function  openDreamList(id){
  router.push({ name: 'dream-list-with-symbol', params: { id: id } })
}


</script>
<style scoped>

</style>