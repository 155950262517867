<template>
  <header-section />
  <main class="mt-5">
    <!--    .container-->
    <div class="container">
      <!--    .account-box-->
      <div class="account-box shadow-lg">

        <div>
          <form>
            <h2 class="account-title">ویرایش پروفایل</h2>
                <img @click.prevent="editAvatar"   :src="avatar" class="mx-auto rounded-circle wh-90 avatar d-block mb-2" alt=""/>
                <div class="d-flex justify-content-center my-2">
                  <a @click.prevent="editAvatar"   href="#" class="btn text-center   btn-sm btn-secondary">ویرایش تصویر</a>
                </div>
            <input accept=".png,.jpg,.jpeg"  @change="onFileChange($event)" type="file" class="d-none" id="input_avatar"/>


            <input v-model="fullName" type="text" class="account-input" placeholder="نام"/>
            <input v-model="birthYear" type="number" class="account-input" placeholder="سال تولد"/>
            <select v-model="gender" class="account-select">
              <option value="">لطفا جنسیت خود را مشخص کنید</option>
              <option value="1">مرد</option>
              <option value="2">زن</option>
              <option value="3">دیگر</option>
            </select>
            <small class="form-text text-danger">{{ errorText }}</small>
            <button :disabled="loading" @click.prevent="editProfile" class="account-btn">
              <div
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"></div>
              ویرایش
            </button>

          </form>
        </div>
      </div>
      <!--   / .account-box-->
    </div>
    <!--    /.container-->

  </main>
  <footer>
    <div class="d-flex justify-content-between">
      <a @click.prevent="back"  href="#" class="footer-icon">
        <i class="fa fa-arrow-right align-middle"></i>
      </a>
      <router-link :to="{name : 'dream-add'}" href="#" class="custom-box3">
        <img src="@/assets/img/svg/note_add.svg" alt=""/>
      </router-link>
      <router-link to="/" href="#" class="footer-icon">
        <img src="@/assets/img/svg/home.svg" alt=""/>
      </router-link>
    </div>
  </footer>
</template>


<script setup>


import {onMounted, ref} from "vue";
import router from "@/router";
import {helper} from "@/helper";
import axios from "axios";
import Swal from "sweetalert2";
import HeaderSection from "@/components/HeaderSection.vue";


const fullName = ref("");
const birthYear = ref("");
const gender = ref("");
const avatar = ref("");
const errorText = ref("");
const loading = ref(false);

helper.refreshToken();


function  editAvatar(){
  let avatar = document.getElementById("input_avatar");
  avatar.click();
}
function  onFileChange(e) {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length)
    return;
  var reader = new FileReader();
  reader.onload = (e) => {
    avatar.value = e.target.result;
  };
  reader.readAsDataURL(files[0]);
}

const Toast = Swal.mixin({
  toast: true,
  position: "bottom",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});

function editProfile() {
  if (fullName.value.toString().length == 0 || birthYear.value.toString().length == 0 || gender.value.toString().length == 0) {
    Toast.fire({
      icon: "error",
      title: "لطفا اطلاعات درخواستی را تکمیل کنید"
    });
    return false;
  }
  if (birthYear.value <= 1300 || birthYear.value >= 1400) {
    Toast.fire({
      icon: "error",
      title: "لطفا تاریخ تولد خود را صحیح وارد کنید"
    });
    return false;
  }
  errorText.value = "";
  //send request to server

  let postData = {
    name: fullName.value,
    birth_year: birthYear.value,
    gender: gender.value,
  }
  if(document.getElementById('input_avatar').files[0]){
    postData.avatar = document.getElementById('input_avatar').files[0];
  }

  axios
      .post(helper.API_URL + '/api/v1/client/editProfile', postData,{headers: {"Authorization": `Bearer ${helper.getCurrentToken()}`,  'Content-Type': 'multipart/form-data'}})
      .then(function (response) {
        errorText.value = "";
        loading.value = false;
        const responseData = response.data;
        if (!responseData.error) {
          Toast.fire({
            icon: "success",
            title: responseData.message
          });
          helper.refreshToken();
        }
      })
      .catch(function (error) {
        loading.value = false;
        let errorMsg = "";
        try {
          const responseData = error.response.data;
          if (responseData.error) {
            errorMsg = responseData.message;
          }
        } catch (e) {
          errorMsg = "امکان اتصال به سرور وجود ندارد ! لطفا اینترنت خود را بررسی کنید";
        }
        Toast.fire({
          icon: "error",
          title: errorMsg
        });
      });



}
  function  back() {
      router.go(-1);
}
onMounted(function () {
  const client = helper.getCurrentClient();
  fullName.value = client.name;
  birthYear.value = client.birth_year;
  gender.value = client.gender;
  avatar.value = client.avatar;
})


</script>

<style scoped>

</style>